import React, {useEffect, useState} from 'react';
import segna, {DataImporter} from "@segna/segna-sdk";
import styled from "@emotion/styled";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DescriptionWrapper = styled.div`
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
`

const ArrowWrapper = styled.div`
  position: absolute;
  left: 16px;
  margin: auto 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  &: hover {
    color: lightgrey;
  }
`

const DownloadLinkWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
`

const DownloadLinkCaption = styled.span`
  font-size: 0.8rem;
  font-weight: bold;
  color: gray;
`

const StyledDownloadLink = styled.a`
  color: white;
  transition: all 100ms ease-in-out;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &: hover {
    color: blue;
  }
`

const NoDownloadLink = styled.span`
  font-style: italic;
`


interface Props {
  onBack: () => void;
}

const DataImporterWrapper: React.FC<Props> = ({onBack}) => {
  const [downloadLink, setDownloadLink] = useState<string | null>(null);
  const [displayFileName, setDisplayFileName] = useState<string | null>(null)

  useEffect(() => {
    segna.initialize({
      apiKey: localStorage.getItem("segna_api_key") || '',
    })
  }, [])

  return (
    <Wrapper>
      <ArrowWrapper onClick={onBack}>
        <ArrowBackIcon/>
      </ArrowWrapper>
      <DescriptionWrapper>
        Click Import to upload your file!
      </DescriptionWrapper>
      <DataImporter pipelineId="tNV3WLBVTwrQ7JcNnk6AxxWlKH4E4uDYCMiuwRw8QZc2"
                    runJobOnCompletion={{
                      returnWhenComplete: true, callbacks: {
                        afterCallback: async ({jobId}) => {
                          if (jobId) {
                            const downloadLinkResponse = await segna.getDownloadLink(jobId);
                            setDownloadLink(downloadLinkResponse.url);
                          }
                        }
                      }
                    }}
                    steps={{
                      fileUpload: {
                        outputFileType: 'excel',
                        scripts: {
                          prePivot: ['wGgh4OmgRN3001189qU0GbvH1NqjQU6ZvA7fojz13dk4'],
                          preColumnMapping: ['RR73PDoOr3S8zFhzW9CEwqifxkkpDzLbfaBvHYxrdoM0']
                        },
                        callbacks: {
                          afterCallback: async ({jobId}) => {
                            if (jobId) {
                              await segna.updatePivotMethods(jobId, {
                                pivot: {
                                  pivotColumns: {'columnNames': ['Period']},
                                  valueColumns: {'columnNames': ['KWh']},
                                  indexColumns: {'columnNames': ['ICP NUMBER', 'SITE NAME']},
                                  aggregationMethod: {'method': 'sum'}
                                }
                              })

                              const dataSourceNamesLabel = await segna.getMetadataLabel(jobId, "dataSourceNames");
                              const fullFileName = Object.keys(dataSourceNamesLabel["dataSourceNames"] as Record<string, string>)[0];
                              const fileName = fullFileName.split('.')[0]
                              const currentDate = new Date();
                              const destinationFileName = `${fileName}_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}`;

                              await segna.updateDestinationFileName(jobId, {
                                destinationFileName
                              })

                              setDisplayFileName(destinationFileName);
                            }
                          }
                        },
                      },
                    }}/>
      <DownloadLinkWrapper>
        <DownloadLinkCaption>Download Link:</DownloadLinkCaption>
        {downloadLink && displayFileName ? <StyledDownloadLink href={downloadLink} target="_blank"
                                                               download>{displayFileName}</StyledDownloadLink> :
          <NoDownloadLink>Link will display here after an upload is complete</NoDownloadLink>}

      </DownloadLinkWrapper>
    </Wrapper>
  );
};

export default DataImporterWrapper;
