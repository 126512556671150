import React, {useRef, useState} from 'react';
import DataImporter from "./data-importer-screen";
import styled from '@emotion/styled';
import logo from './segna-logo-teal-white.png';
import ApiKeyScreen from "./api-key-screen";
import {animated, useSpring} from '@react-spring/web'


const Wrapper = styled.div`
  position: relative;
  background-color: rgb(26, 32, 45);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`

const FooterContent = styled.div`
  position: absolute;
  bottom: 16px;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
`

const LogoWrapper = styled.img`
  margin-bottom: 16px;
`

const ContentSection = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  border-radius: 4px;
  width: 500px;
  height: 120px;
  background-color: rgb(40, 45, 60);
`

const AnimatedDiv = styled(animated.div)`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
`

function App() {
  const refForInitialLoad = useRef<HTMLInputElement | null>(null);
  const [isApiKeySet, setIsApiKeySet] = useState<boolean>(() => Boolean(localStorage.getItem("segna_api_key")))
  const styles = useSpring({
    to: {opacity: 1, x: 0},
    from: {opacity: 0, x: isApiKeySet ? 100 : -100},
    reset: true,
    immediate: !refForInitialLoad.current
  })

  const onBack = () => {
    setIsApiKeySet(false);
  }

  const onNext = () => {
    setIsApiKeySet(true);
  }

  return (
    <Wrapper ref={refForInitialLoad}>
      <LogoWrapper src={logo} className="App-logo" alt="logo"/>
      <ContentSection>
        <AnimatedDiv style={styles}>
          {isApiKeySet ?
            <DataImporter onBack={onBack}/>
            :
            <ApiKeyScreen onNext={onNext}/>
          }
        </AnimatedDiv>
      </ContentSection>
      <FooterContent>
        <div style={{marginRight: 32}}><a href={"https://www.segna.io/terms-conditions"} target="_blank_"
                                          style={{color: 'white'}}>Terms and Conditions</a></div>
        <div> Copyright 2022 Segna Inc. All Rights Reserved.
        </div>
      </FooterContent>
    </Wrapper>
  );
}

export default App;
